@import 'variables/fonts';
@import 'variables/spaces';
@import 'variables/transitions';
@import 'variables/colors.scss';

@import 'helpers/breakpoints';

.formButtonGroup {
	button {
		margin-left: 15px;
	}
	a > button {
		margin: 0;
	}
}

.skeletonLoader {
	background-color: $white;
	background-image: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
	animation: skletalAnimation 1s ease-in-out infinite;
}

@keyframes skletalAnimation {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

@import 'variables/index';

@import 'utilities/index';

@import 'trumps/index';

@import 'base/index';

@import 'framework_overrides/index';

@import 'responsive/index';

@include if-device(phone) {
	.ant-modal.policy-detail-modal {
		height: 100vh !important;
		.ant-modal-content {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			&::before {
				display: none;
			}
		}
		.ant-modal-close {
			display: none;
		}
		.ant-modal-body {
			padding: 10px 20px 40px 20px;
			min-height: 50vh;
			h3 {
				margin: 24px 0;
			}
			.policy-text-wrapper {
				padding: 16px;
			}
		}
	}
}
