@font-face {
	font-family: $font-family-eina;
	font-style: normal;
	font-weight: $font-bold;
	src: url('../../assets/fonts/Eina/Eina-Bold.woff2') format('woff2'),
		url('../../assets/fonts/Eina/Eina-Bold.woff') format('woff');
}

@font-face {
	font-family: $font-family-eina;
	font-style: normal;
	font-weight: $font-regular;
	src: url('../../assets/fonts/Eina/Eina-Regular.woff2') format('woff2'),
		url('../../assets/fonts/Eina/Eina-Regular.woff') format('woff');
}

@font-face {
	font-family: $font-family-eina;
	font-style: normal;
	font-weight: $font-semi-bold;
	src: url('../../assets/fonts/Eina/Eina-SemiBold.woff2') format('woff2'),
		url('../../assets/fonts/Eina/Eina-SemiBold.woff') format('woff');
}

@font-face {
	font-family: $font-family-circular;
	font-style: normal;
	font-weight: $font-regular;
	font-display: swap;
	src: url('../../assets/fonts/CircularXX/CircularXXWeb-Regular.woff2')
			format('woff2'),
		url('../../assets/fonts/CircularXX/CircularXXWeb-Regular.woff') format('woff');
}

@font-face {
	font-family: $font-family-circular;
	font-style: normal;
	font-weight: $font-semi-bold;
	font-display: swap;
	src: url('../../assets/fonts/CircularXX/CircularXXWeb-Medium.woff2')
			format('woff2'),
		url('../../assets/fonts/CircularXX/CircularXXWeb-Medium.woff') format('woff');
}

@font-face {
	font-family: $font-family-circular;
	font-style: normal;
	font-weight: $font-bold;
	font-display: swap;
	src: url('../../assets/fonts/CircularXX/CircularXXWeb-Bold.woff2')
			format('woff2'),
		url('../../assets/fonts/CircularXX/CircularXXWeb-Bold.woff') format('woff');
}
