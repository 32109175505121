.ant-tabs.page-nav {
	background-color: $grey-1;
	.ant-tabs-nav {
		height: 60px;
		background-color: $white;
		.ant-tabs-tab {
			.ant-tabs-tab-btn {
				color: $text-grey-light;
				font-weight: $font-semi-bold;
			}
			&.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					color: $tomato;
				}
			}
		}
		.ant-tabs-ink-bar {
			background: $tomato;
		}
	}
	.ant-tabs-content-holder {
		min-height: calc(100vh - 156px);
	}
}
