// Global Font Variables
$global-font-size: 16px;
$global-line-height: 1.3;
$font-family-poppins: 'Poppins';
$font-family: $font-family-poppins;

// Font Weights
$font-regular: 400;
$font-semi-bold: 600;
$font-bold: 700;

.text-center {
	text-align: center;
}
.text-underline {
	text-decoration: underline;
}
.text-capitalize {
	text-transform: capitalize;
}
