.ant-dropdown {
	width: 180px;
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
}

.ant-dropdown-trigger {
	&.filter-dropdown {
		text-transform: capitalize;
		cursor: pointer;
		border-radius: 12px;
		border: 1px solid $grey-2;
		padding: 12px 32px 12px 16px;
		width: 100%;
		font-size: 14px;
		display: inline-block;
		line-height: 1;
		position: relative;
		background-color: white;
		&.ant-dropdown-open {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
			background-color: $white;
		}
		&::before {
			content: '';
			position: absolute;
			right: 10px;
			height: 20px;
			width: 20px;
			background-image: url('./../../assets/images/icons/arrow.svg');
			transform: rotate(-90deg);
			top: 9px;
		}

		&.highlight {
			color: $tomato;
			&::before {
				content: '';
				position: absolute;
				right: 16px;
				height: 18px;
				width: 18px;
				background-image: url('./../../assets/images/icons/arrow-highlight.svg');
				transform: rotate(0deg);
				top: 9px;
				background-size: contain;
			}
		}
	}
}

.ant-dropdown.filter-dropdown-wrapper {
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	box-shadow: 0px 2px 8px rgba(9, 30, 66, 0.04),
		0px 12px 32px rgba(9, 30, 66, 0.12);
	padding: 8px 0;
	border: 1px solid $grey-2;
	border-top: none;
	background-color: $white;
	.filter-dropdown-options {
		box-shadow: none;
		border: none;
		border-bottom-left-radius: 32px;
		border-bottom-right-radius: 32px;
		li {
			font-size: 14px;
			padding: 8px 16px;
		}
	}
}

@include if-device(phone) {
	.ant-dropdown.filter-dropdown-wrapper {
		.filter-dropdown-options {
			li {
				white-space: break-spaces;
				border-bottom: 1px solid $grey-2;
			}
		}
	}
	.ant-dropdown-trigger.filter-dropdown {
		height: fit-content;
	}
}
