$black-rock: #091e42;
$g6: #45526c;
$g5: #7c8698;
$white: #ffffff;
$purple: #9a407f;

$grey-1: #f4f5f7;
$grey-2: #dfe1e6;
$grey-3: #b4bac4;
$grey-4: #dbdbdb;
$grey-5: #f2f5f7;
$grey-6: #f5f5f5;
$grey-7: #f1f2f3;
$grey-8: #6b6f77;
$grey-9: #40444f;
$grey-10: #343945;
$grey-11: #d6d7da;

$blue-light: #20304a;
$blue-light-1: #a6acb7;
$blue-light-2: #7c8698;
$blue-light-3: #53627b;
$blue-light-4: #7f8a9d;

$green-light: #e6f6f1;
$green-light-1: #9addc6;

$red-100: #fdecec;
$red: #e32525;
$tomato: #e95454;
$red-2: #ee7a7a;

$text-grey: #2d3748;
$text-grey-light: $g5;
$text-grey-medium: #4a5568;
$text-grey-dark: #1a202c;
$text-purple: $purple;
$text-red: $red;

$background-red: $red-100;
$background-grey: $grey-5;
$background-grey2: $grey-10;

$pastel-pink: #e7c4dc;
$pastel-blue: #eef8fe;
$pastel-green: #e6f6f1;
$pastel-violet: #f3effe;
$pastel-light-red: #fdf4f5;
$pastel-light-orange: #f7f3ee;

$white_transclusent: #ffffff99;

$blue: #4f8afb;
$blue-bright: #2badf8;
$linkedin-blue: #056da9;
$navy-blue: #044971;
$dark-navy-blue: #071936;

$violet-deep: #240a6d;
$violet-bright: #9473f2;

$light-blue: #c4d8fe;
$lightest-blue: #ebf2ff;
$green: #02a971;
$green-1: #028d5e;

$pale-red: #e77059;

// Event type backgrounds
$pale-violet: #f3effe;
$pale-cream: #f8f3ee;
$pale-brown: #fef5f5;

// Event type borders
$pastel-light-violet: #dbd0fb;
$pastel-light-brown: #f7cfc8;
$pastel-light-beige: #ebd7c4;

$border-red: $red-2;
$border-grey1: $grey-8;
$border-grey2: $grey-9;
