/* stylelint-disable */
// ====== Media Devices ======

$scu-large-desktop-min-width: 1400px !default;
$scu-large-desktop-min-height: 800px !default;
$scu-desktop-min-width: 1280px !default;
$scu-tablet-min-width: 768px !default;
$scu-tablet-max-width: 1025px !default;
$scu-phone-max-width: 767px !default;
$scu-small-phone-max-width: 360px !default;

@mixin less-than-width($device-width) {
	@media only screen and (max-width: $device-width - 1) {
		@content;
	}
}

@mixin more-than-width($device-width) {
	@media only screen and (min-width: $device-width + 1) {
		@content;
	}
}

@mixin at-least-width($device-width) {
	@media only screen and (min-width: $device-width) {
		@content;
	}
}

@mixin until-width($device-width) {
	@media only screen and (max-width: $device-width) {
		@content;
	}
}

@mixin if-device($device) {
	@if $device == large-desktop {
		@media only screen and (min-width: $scu-large-desktop-min-width) and (min-height: $scu-large-desktop-min-height) {
			@content;
		}
	} @else if $device == desktop {
		@media only screen and (min-width: $scu-desktop-min-width) {
			@content;
		}
	} @else if $device == tablet {
		@media only screen and (min-width: $scu-tablet-min-width) and (max-width: $scu-tablet-max-width) {
			@content;
		}
	} @else if $device == desktop-tablet {
		@media only screen and (min-width: $scu-tablet-min-width) {
			@content;
		}
	} @else if $device == phone-tablet {
		@media only screen and (max-width: $scu-tablet-max-width) {
			@content;
		}
	} @else if $device == phone {
		@media only screen and (max-width: $scu-phone-max-width) {
			@content;
		}
	} @else if $device == small-phone {
		@media only screen and (max-width: $scu-small-phone-max-width) {
			@content;
		}
	} @else if $device == retina {
		@media only screen and (-o-min-device-pixel-ratio: 13/10),
			only screen and (-webkit-min-device-pixel-ratio: 1.3),
			only screen and (min-resolution: 120dpi) {
			@content;
		}
	}
}

// @2x Background image
@mixin bg-image-2x($file, $type, $bg-size: false, $no-repeat: true) {
	background-image: url($file + '.' + $type);

	@if $no-repeat {
		background-repeat: no-repeat;
	}

	@if $bg-size {
		-webkit-background-size: $bg-size; // Needed for Android v < 3
		background-size: $bg-size;
	}

	@media print,
		(-o-min-device-pixel-ratio: 5/4),
		(-webkit-min-device-pixel-ratio: 1.25),
		(min-resolution: 120dpi),
		(min-resolution: 2dppx) {
		& {
			background-image: url($file + '@2x.' + $type);
		}
	}
}

@mixin subject-image($name, $size, $bg-size, $bg-color) {
	@include bg-image-2x('../images/web/subjects/' + $name, png, $bg-size);
	@include border-radius(50%);
	background-position: center center;
	width: $size;
	height: $size;
	background-color: $bg-color;
}
