#root {
	.ant-btn {
		line-height: 1;
		height: auto;
		font-weight: $font-semi-bold;
		&.fill-width {
			width: 100%;
		}
		&.mobile-fill-width {
			width: inherit;
		}
	}
	.ant-btn-primary {
		background-color: $tomato;
		border: none;
		border-radius: 8px;
		font-size: 1.2857142em;
		padding: 13px 16px;
		transition: all 0.3s $easing-expressive;
		background-position: 0 0;
		height: 42px;
		text-shadow: unset;
		&.animated {
			&:hover {
				background-position: 149px 0;
			}
		}
		&[disabled] {
			background-color: $grey-3 !important;
			color: $white !important;
			pointer-events: none;
			cursor: not-allowed;
		}
		&.--with-arrow {
			position: relative;
			padding-right: 44px;
			&::before {
				content: '';
				display: block;
				background: url('./../../assets/images/icons/circle-arrow.svg');
				height: 16px;
				width: 16px;
				position: absolute;
				right: 16px;
				top: 50%;
				transform: translateY(-50%);
				left: auto;
				bottom: auto;
				opacity: 1;
			}
		}
		&.--with-hollow-arrow {
			position: relative;
			padding-right: 38px;
			&::before {
				content: '';
				display: block;
				background: url('./../../assets/images/icons/arrow-right-light.svg');
				height: 24px;
				width: 24px;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				left: auto;
				bottom: auto;
				opacity: 1;
			}
		}
		&.--with-hollow-arrow-down {
			position: relative;
			padding-right: 38px;
			&::before {
				content: '';
				display: block;
				background: url('./../../assets/images/icons/arrow-right-light.svg');
				height: 24px;
				width: 24px;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%) rotate(90deg);
				left: auto;
				bottom: auto;
				opacity: 1;
			}
		}
		&.hollow-btn {
			padding: 12px 15px;
			background: $white;
			border: 1px solid #b4bac4;
			box-shadow: none;
			color: $text-grey-medium;
			font-size: 1.142857em;
		}
		&.grey-btn {
			color: $white;
			background-color: $text-grey-medium;
			border: 1px solid $text-grey-medium;
			box-shadow: 0px 0px 4px rgba(51, 54, 68, 0.05),
				0px 2px 6px rgba(51, 54, 68, 0.1);
			&:hover {
				border: 1px solid $text-grey-medium;
			}
		}
	}
	.ant-btn.secondary-btn {
		background: $white;
		border-radius: 6px;
		font-weight: $font-semi-bold;
		color: $text-grey;
		padding-top: 10px;
		padding-bottom: 10px;
		border: none;
		box-shadow: 0px 2px 6px 0px rgba(51, 54, 68, 0.1);
		&.ant-btn-lg {
			padding: 14px 48px;
		}
	}
	.ant-btn.red-text-btn {
		font-size: 1.142857em;
		color: $tomato;
		font-weight: $font-semi-bold;
		margin-right: 8px;
		border: none;
		box-shadow: none;
		&.--with-arrow {
			position: relative;
			padding-right: 38px;
			&::before {
				content: '';
				display: block;
				background: url('./../../assets/images/icons/circle-arrow--red.svg');
				height: 16px;
				width: 16px;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				left: auto;
				bottom: auto;
				opacity: 1;
			}
		}
	}
	.primary--no-gradient {
		background: $tomato;
	}
}

// FIXME
.ant-modal-root {
	.ant-btn {
		line-height: 1;
		height: auto;
		font-weight: $font-semi-bold;
		&.fill-width {
			width: 100%;
		}
	}
	.ant-btn-primary {
		background: $tomato;
		border: none;
		border-radius: 8px;
		font-size: 18px;
		padding: 13px 16px;
		transition: all 0.3s $easing-expressive;
		background-position: 0 0;
		height: 42px;
		&.animated {
			&:hover {
				background-position: 149px 0;
			}
		}
		&[disabled] {
			background: $grey-3 !important;
			color: $white !important;
			pointer-events: none;
			cursor: not-allowed;
		}
		&.--with-arrow {
			position: relative;
			padding-right: 44px;
			&::before {
				content: '';
				display: block;
				background: url('./../../assets/images/icons/circle-arrow.svg');
				height: 16px;
				width: 16px;
				position: absolute;
				right: 16px;
				top: 50%;
				transform: translateY(-50%);
				left: auto;
				bottom: auto;
				opacity: 1;
			}
		}
	}
	.ant-btn.secondary-btn {
		background: $white;
		border-radius: 6px;
		font-weight: $font-semi-bold;
		color: $text-grey;
		padding-top: 10px;
		padding-bottom: 10px;
		border: none;
		box-shadow: 0px 2px 6px 0px #333644 10%;
	}
}

@include if-device(phone) {
	#root {
		.ant-btn {
			&.ant-btn-primary {
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 14px;
				height: 38px !important;
			}
			span {
				font-size: 14px;
			}
			&.mobile-fill-width {
				width: 100%;
			}
		}
	}

	.ant-modal-root {
		.ant-btn {
			&.ant-btn-primary {
				padding: 10px 14px;
				height: 38px !important;
			}
		}
	}
}

@include if-device(small-phone) {
	#root {
		.ant-btn {
			span {
				font-size: 12px;
			}
		}
	}

	.ant-modal-root {
		.ant-btn {
			span {
				font-size: 12px;
			}
		}
	}
}
