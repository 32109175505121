#root {
	.ant-layout-content {
		max-width: 1120px;
		margin: 80px auto 0 auto;
		&.no-margin {
			margin-top: 0;
		}
	}
}

@include if-device(phone) {
	#root {
		.ant-layout-content {
			max-width: calc(100vw - 40px);
			margin: 32px auto 60px auto;
		}
	}
}
