.icon {
	color: inherit;
	background-size: contain;
	min-width: 12px;
	min-height: 12px;
	background-position: center;
	background-repeat: no-repeat;
}

.icon-16 {
	width: 16px;
	height: 16px;
}
.icon-20 {
	width: 20px;
	height: 20px;
}
.icon-24 {
	width: 24px;
	height: 24px;
}
.icon-30 {
	width: 30px;
	height: 30px;
}
