@import './grid.scss';
@import './buttons';
@import './cards';
@import './modals';
@import './tabs';
@import './dropdown.scss';

#root {
	.reset-font-size {
		font-size: 1em;
	}
}

body {
	font-feature-settings: 'tnum' off;
}

.d-ib {
	display: inline-block;
}

.body-only {
	.ant-notification-notice-with-icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.mobile-not-supported {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 40px;
	flex-direction: column;
	h1 {
		font-size: 18px;
		text-align: center;
	}
	h4 {
		font-size: 14px;
		color: $text-grey-light;
	}
}
