.hide-on-desktop {
	display: none;
}
.hide-on-mobile {
	display: block;
}
@include if-device(phone) {
	.hide-on-desktop {
		display: block;
	}
	.hide-on-mobile {
		display: none !important;
	}
}
