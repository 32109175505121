@import 'fonts';

body {
	height: 100%;
	background-color: $white;
	overflow-x: hidden;
	font-size: 14px;
	line-height: 1.4;
	font-weight: 400;
	letter-spacing: normal;
	font-family: $font-family, sans-serif;
	text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: geometricPrecision;
	font-variant-ligatures: common-ligatures;
	color: $text-grey;
}

body * {
	transition: 0.2s $easing-productive;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $font-semi-bold;
}

h1 {
	font-size: 2.571428em; // 36px
}

h2 {
	font-size: 1.857142em; // 26px
}

h3 {
	font-size: 1.428571em; // 20px
}

h4 {
	font-size: 1.142857em; // 16px
}

h5 {
	font-size: 1.571428em; // 22px
}

h6 {
	font-size: 1em;
}

li {
	list-style-type: none;
}

small {
	font-size: 12px;
}

img {
	/* Makes sure image is never bigger than its parent */
	max-width: 100%;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $grey-3;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: $grey-3;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: $grey-3;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: $grey-3;
}

hr {
	border: none;
	border-bottom: 1px solid $grey-2;
}
button:disabled {
	opacity: 0.6;
}

.background_icon {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}

hr.pageRule {
	margin: 100px auto 80px auto;
	max-width: 1120px;
	border: none;
	border-top: 1px solid $grey-2;
}
@include if-device(phone) {
	body {
		font-size: 10px;
	}
}
